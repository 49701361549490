import React from 'react';
import Footer from '../../layouts/partials/footer';
import Header from '../../layouts/partials/header';

const PrivacyPolicy = () => {
  return (
    <div>
  <Header/>
 
    <section className="text-booking pad-tb companyProfile-section">
       <div className="container">
          <div className="row">
             <div className="col-md-12">
                <div className="process-text m1-h m2-h p1">
                   <h1>Privacy Policy</h1><br/>
 
                   <p>
                      By accessing and using the TOYOTA website, you accept the following provisions without restriction
                      and in their entirety. Access to the TOYOTA website is subject to both the legal bases listed below
                      and the currently applicable laws.
                   </p>
 
                   <p>
                      Copyright © 2020 TOYOTA RawalMotors
                   </p>
 
                   <div className="privact-policy-cont">
                      <h3>content</h3>
 
                      <p>
                         The TOYOTA website contains information about TOYOTA products and promotions. The TOYOTA
                         products described on this website are only available for sale in Europe and only from
                         authorized TOYOTA dealers. Likewise, the promotions described on this website are only carried
                         out in the countries explicitly listed under the respective promotion.
                      </p>
 
                      <p>
                         All information contained on this website is for informational purposes only. Therefore, this
                         website cannot be used as a substitute for information that an authorized TOYOTA dealer can give
                         you. The information contained on this website is not binding and does not constitute an offer
                         to sell.
                      </p>
 
                      <p>
                         The product images on this website may differ in individual details from models available in
                         Germany or may contain optional extras and accessories that are subject to a surcharge.
                      </p>
 
                      <p>
                         We try to ensure that the information given here is as complete as possible. TDG reserves the
                         right, however, to make changes to models, equipment, technical data and the availability of
                         vehicles at any time and without prior notice.
                      </p>
 
                      <h3>TOYOTA product prices</h3>
 
                      <p>
                         The price information contained on this website or accessible via the website is for
                         informational purposes only and does not constitute a binding offer for the sale of TOYOTA
                         products. All prices are recommended retail prices from Toyota Deutschland GmbH plus delivery
                         costs. Accessory equipment plus assembly costs, which may differ from the actual prices of your
                         authorized TOYOTA dealer. The purchase of any TOYOTA product is subject to the terms of the
                         respective sales contract.
                      </p>
 
                      <h3>Use of "cookies"</h3>
 
                      <p>
                         TDG uses cookies to keep track of which pages you visit within the TOYOTA website. These cookies
                         are used to track the activities of users within the TOYOTA website and to evaluate or improve
                         the website so that it can provide you with even more useful information. TDG does not record
                         any information from individual users, ie no information about you is specifically collected or
                         used. We would like to point out that you can set your browser so that it does not accept
                         cookies or that you receive a warning message when cookies are being sent.
                      </p>
 
                      <h3>Personal data / user input</h3>
 
                      <p>
                         Personal data that you provide to TDG via the TOYOTA website will only be used by TDG to improve
                         the services we offer. TDG takes all possible precautions to ensure the secure collection,
                         transmission and storage of personal and correspondingly sensitive data. TDG will not use your
                         personal data to send you unsolicited information or communications, nor will TDG make this data
                         available to third parties or sell it to third parties without your consent.
                      </p>
 
                      <p>
                         TDG is not obliged to monitor or check entries made by users on the TOYOTA website and assumes
                         no responsibility or liability for such entries. TDG reserves the right, however, to check such
                         user entries from time to time and to remove them from the website without specifying reasons.
                      </p>
 
                      <h3>Copyright, industrial property rights and intellectual property</h3>
 
                      <p>
                         The presentation of the information and the entire content of this website are protected by
                         industrial property rights and are the intellectual property of TDG. You only have the right to
                         use the information, texts, images or graphics contained here for personal, non-commercial
                         purposes and may not reproduce, modify, transfer, license or publish them in whole or in part
                         without the prior written consent of TDG, unless to view.
                      </p>
 
                      <h3>trademark</h3>
 
                      <p>
                         All trademarks, logos, and service marks appearing on this website are the property of Toyota
                         Motor Corporation of Japan or of third parties. You are expressly prohibited from using,
                         permanently downloading, copying or distributing them in any way without the prior written
                         consent of Toyota Motor Corporation or any third party.
                      </p>
 
                      <h3>Hyperlinks</h3>
 
                      <p>
                         The TOYOTA website may contain hyperlinks to other Internet sites that are completely
                         independent of this website. TDG assumes no liability for the correctness, completeness or
                         authenticity of the information contained in such hypertext links or other Internet sites.
                         Access to other Internet sites that are linked to the TOYOTA website is at your own risk. <br/>
                         The establishment of a hyperlink from other websites to a website belonging to www.toyota.de is
                         expressly prohibited without the prior written consent of TDG. In particular, it is not
                         permitted to integrate or display the websites belonging to this website or their contents using
                         a hyperlink in a partial window of other online offers.
                      </p>
 
                      <h3>Disclaimer of liability</h3>
 
                      <p>
                         TDG makes every effort to ensure that all information contained on this website is correct.
                         However, TDG cannot guarantee the accuracy of the information on the website and assumes no
                         liability for the correctness, completeness and authenticity of the information contained on the
                         website. This website and all of the material and information contained on it are made available
                         to you in the given form and without any express or implied liability on the part of TDG.
                      </p>
 
                      <h3>Limitation of Liability</h3>
 
                      <p>
                         TDG expressly excludes any liability for direct, indirect, incidental, special or consequential
                         damage in any form in connection with your access to the TOYOTA website. This damage includes,
                         but is not limited to, loss or damage that could result from viruses on your computer or EDP
                         system or from your reliance on information obtained through the TOYOTA website.
                      </p>
 
                      <h3>Change in the legal basis</h3>
 
                      <p>
                         TDG reserves the right to change the present legal basis at any time and recommends that you
                         call up this page every time you visit the TOYOTA website so that you can find out about the
                         latest status.
                      </p>
 
                      <h3>Disclaimer of color choice</h3>
 
                      <p>
                         Due to the representation on the screen, the colors shown here may differ slightly from the
                         actual colors. Some colors may not be available in all countries.
                      </p>
 
                   </div>
                </div>
             </div>
          </div>
       </div>
    </section>
    <Footer/>
    </div>
  );
}

export default PrivacyPolicy;
