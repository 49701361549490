import React from 'react';

const MapSection = () => {
  return (
    <div>
      
   <section className="mapSection">

      <iframe id="i-load"
         src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3325.4408564761648!2d73.10492801515127!3d33.54192035189732!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfed3c5c453fb3%3A0x9af8d0ff4e39a653!2sToyota%20Rawal%20Motors!5e0!3m2!1sen!2s!4v1638338230783!5m2!1sen!2s"
         width="600" height="450" style={{border:"0"}}></iframe>
   </section>


    </div>
  );
}

export default MapSection;

   

